import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { useState, useContext, useEffect } from "react";
import PinInput from "react-pin-input";
import AuthContext from "../store/AuthContext";
import { postJsonData } from "../network/ApiController";
import ResetMpin from "./ResetMpin";
import useCommonContext from "../store/CommonContext";
import { UpiLogo } from "../iconsImports";

const RetUpiTransferModal = ({ ben, rem_number,type }) => {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const { getRecentData,refreshUser } = useCommonContext();
  const [mpin, setMpin] = useState("");
  const [amount, setAmount] = useState(""); // State for amount
  const [err, setErr] = useState();
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const loc = authCtx.location && authCtx.location;
  const [isOtpShow, setIsOtpShow] = useState(false);
  const [stateresp, setStateresp] = useState("");
  const [remOtp, setRemOtp] = useState();
  const [onComplete, setOnComplete] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    boxShadow: 24,
    fontFamily: "Poppins",
    height: "max-content",
    overflowY: "scroll",
    p: 2,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setErr(null); // Reset error on close
    setMpin(""); // Reset MPIN on close
    setAmount(""); // Reset amount on close
    setOnComplete(false);
    setRemOtp("");
    setIsOtpShow(false);
    refreshUser();
  };
  const handleOtpChange = (event) => {
    const value = event.target.value;

    // Prevent input if it's not numeric
    if (/[^0-9]/.test(value)) {
      return;
    }

    // Limit the OTP length to 6 and ensure it's at least 4
    if (value.length <= 6) {
      setErr(""); // Clear error if length is valid
      setRemOtp(value); // Update OTP value
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    let amt = parseInt(amount);
    // Validation Logic
    if (amount === "") {
      setErr({ message: "Amount required" });
      return;
    }
    if (mpin === "") {
      setErr({ message: "MPIN required" });
      return;
    } else if (amt && amt > parseInt(user.w1)) {
      setErr("");
      const error = {
        message: "Low Balance",
      };
      setErr(error);
    }

    const data = {
      vpa: ben.bene_acc,
      latitude: loc.lat,
      longitude: loc.long,
      number: rem_number,
      pf: "WEB",
      mpin: mpin,
      type: type,
      amount: amt,
      name: ben.bene_name,
      otp_ref: stateresp,
      otp: remOtp,
    };

    setRequest(true);
    postJsonData(
      ApiEndpoints.UPI_PAY,
      data,
      setRequest,
      (res) => {
        okSuccessToast(res.data.message);
        getRecentData();
        handleClose();
        refreshUser()
      },
      (error) => {
        getRecentData();
        if (error && error.response) {
          if (error.response.data.message === "Invalid M Pin") {
            setErr(error.response.data);
          } else {
            // apiErrorToast(error);
          }
        }
         apiErrorToast(error);
      }
    );
  };
  const sendOtpMt = () => {
    console.log("im indside apicall");
    const data = {
      number: rem_number && rem_number,
      amount: amount && amount,
      ben_id: ben.benid,
      latitude: loc.lat,
      longitude: loc.long,
      pf: "WEB",
    };
    postJsonData(

   ApiEndpoints.OTP_EXP,
      data,
      setRequest,
      (res) => {
        console.log("reeeees", res);
        setStateresp(res?.data?.data);
        setIsOtpShow(true);
      },
      (err) => {
        console.log("errrrrr", err);
      }
    );
  };
  // Effect to validate amount and control M-PIN visibility
  useEffect(() => {
    const amt = parseInt(amount);
    if (amount !== "" && (amt < 100 || amt >= 250000)) {
      setErr({ message: "Amount must be between 100 to 25000" });
    } else {
      setErr(null); // Clear error if valid
    }
  }, [amount]);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Button
        size="small"
        id="verify-btn"
        className="button-pinkback"
        sx={{
          fontSize: "13px",
          py: 0,
          ml: 1,
          px: 1,
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleOpen}
      >
        <span style={{ marginRight: "5px" }}>UPI</span>
        <img
          src={UpiLogo}
          alt="UPI logo"
          style={{ width: "18px", height: "20px" }}
        />
      </Button>

      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="sm_modal">
            <ModalHeader title="UPI Transfer" handleClose={handleClose} />
            <Box
              component="form"
              id="expMoneyTransfer"
              validate
              autoComplete="off"
              onSubmit={handleSubmit}
              sx={{ "& .MuiTextField-root": { m: 1 } }}
            >
              <Grid container sx={{ pt: 1 }}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <table className="mt-table">
                    <tr>
                      <td>Name</td>
                      <td>:</td>
                      <td style={{ textAlign: "right" }}>{ben.bene_name}</td>
                    </tr>
                    <tr>
                      <td>VPA</td>
                      <td>:</td>
                      <td style={{ textAlign: "right" }}>{ben.bene_acc}</td>
                    </tr>
                  </table>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControl sx={{ width: "74%" }}>
                  <TextField
                    label="Enter Amount"
                    id="amount"
                    size="small"
                    type="number"
                    required
                    value={amount}
                    InputProps={{
                      inputProps: { min: "0", },
                      endAdornment: (
                        <InputAdornment position="end">
                          {amount.length > 2 && !isOtpShow && (parseInt(amount) >= 100 && parseInt(amount) <= 25000)&& (
                            <Button
                              sx={{ py: 0.5, fontSize: "10px", px: -1 }}
                              variant="contained"
                              edge="end"
                              onClick={sendOtpMt}
                              disabled={err?.data ? true : false} // Disable button if amount length is less than 3
                            >
                              Get OTP
                            </Button>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setAmount(event.target.value);
                    }}
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    onKeyDown={(e) => {
                      // Prevent typing "+" or "-" characters
                      if (e.key === "+" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
                </Grid>
                {isOtpShow && (
                <Grid container sx={{ pt: 1 }}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                    hidden={onComplete}
                  >
                    <FormControl sx={{ width: "74%" }}>
                      <TextField
                        label="Enter OTP"
                        id="otp"
                        size="small"
                        type="text" // Use "text" type because "number" input type doesn't respect maxLength
                        required
                        value={remOtp}
                        onChange={handleOtpChange}
                        inputProps={{
                          form: { autocomplete: "off" },
                          maxLength: 6, // Set maximum length for the input
                        }}
                        error={
                          remOtp?.length > 0 &&
                          (remOtp?.length < 4 || remOtp?.length > 6)
                        }
                        helperText={
                          remOtp?.length > 0 && remOtp?.length < 4
                            ? "OTP must be at least 4 digits"
                            : remOtp?.length > 6
                            ? "OTP cannot exceed 6 digits"
                            : ""
                        }
                        onKeyDown={(e) => {
                          // Prevent typing "+" or "-" characters
                          if (e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
          
                </Grid>
              )}
               
                {isOtpShow && amount !== "" && (parseInt(amount) >= 9 && parseInt(amount) <= 25000) && (
                  <>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <FormControl>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Enter M-PIN
                        </Typography>
                        <PinInput
                          length={6}
                          type="password"
                          onChange={(value) => {
                            if (err) {
                              setErr(null); // Clear error if valid
                            }
                            setMpin(value);
                          }}
                          regexCriteria={/^[0-9]*$/}
                          inputStyle={{
                            width: "40px",
                            height: "40px",
                            marginRight: { xs: "3px", md: "5px" },
                            textAlign: "center",
                            borderRadius: "0",
                            border: "none",
                            borderBottom: "1px solid #000",
                            padding: "5px",
                            outline: "none",
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        pr: 12,
                        mt: 1,
                      }}
                    >
                      <Button sx={{ mt: -2, alignItems:"end"}}>
                      <ResetMpin variant="text" py mt  />
                    </Button>

                    </Grid>
                  </>
                )}

                {err && (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                        fontSize: "12px",
                        px: 2,
                        color: "#DC5F5F",
                      }}
                    >
                      <div>{err.message}</div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <ModalFooter 
              form="expMoneyTransfer" 
              request={request} 
              btn="Proceed" 
              disable={remOtp?false:true}
              />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default RetUpiTransferModal;
